import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {EditorService} from './services/editor.service';
import {WorkspaceService} from '../pb-workspace/service/workspace.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-pb-editor',
    templateUrl: './pb-editor.component.html',
    styleUrls: ['./pb-editor.component.scss']
})
export class PbEditorComponent implements OnInit {

    private form: FormGroup;
    private listenerDown: (event) => any;
    private listenerUp: (event) => any;

    constructor(
        private fb: FormBuilder,
        private editorService: EditorService,
        private workspace: WorkspaceService,
    ) {

        this.listenerDown = (event) => this.onKeyDown(event);
        this.listenerUp = (event) => this.onKeyUp(event);
    }

    ngOnInit() {
        this.editorService.changesObserver.subscribe(
            result => {
                this.createForm();
            }
        );

        this.createForm();

        document.removeEventListener('keyup', this.listenerUp);
        document.removeEventListener('keydown', this.listenerDown);
        document.addEventListener('keyup', this.listenerUp);
        document.addEventListener('keydown', this.listenerDown);
    }


    private createForm() {
        if (this.editorService.editionList.length > 0) {
            if (this.editorService.editionList.length === 1) {
                this.form = this.fb.group(
                    {
                        weight: [this.editorService.editionList[0].weight],
                        importance: [this.editorService.editionList[0].importance],
                        tags: [this.editorService.editionList[0].tags.join(', ')],
                        description: [this.editorService.editionList[0].description],
                        addTags: [''],
                        deleteTags: [''],
                    }
                );
            } else {
                this.form = this.fb.group(
                    {
                        weight: [this.editorService.editionList[0].weight],
                        importance: [''],
                        addTags: [''],
                        deleteTags: [''],
                    }
                );
            }
        }

    }

    public onWeightChange($event) {
        const weight = this.form.value.weight;

        for (let i = 0; i < this.editorService.editionList.length; i++) {
            const from = this.editorService.editionList[i].weight;
            const to = weight > from ? weight + this.editorService.editionList.length - 1 : weight + i;
            moveItemInArray(this.workspace.workingAlbum.workingMedia, from, to);
            this.workspace.workingAlbum.updateWeights();
        }
        this.workspace.workingAlbum.updateViewMediaList();
    }


    public save() {
        if (this.editorService.editionList.length === 1) {
            this.editorService.editionList[0].importance = this.form.value.importance;
            this.editorService.editionList[0].tags = this.getTags(this.form.value.tags);
            this.editorService.editionList[0].description = this.form.value.description;
        } else {
            this.editorService.editionList.map(workingMedia => {
                workingMedia.importance = this.form.value.importance.length > 0 ? this.form.value.importance : workingMedia.importance;
                workingMedia.addTags(this.getTags(this.form.value.addTags));
                workingMedia.deleteTags(this.getTags(this.form.value.deleteTags));
            });
        }

        this.workspace.workingAlbum.initData();
        this.workspace.workingAlbum.updateViewMediaList();
        this.workspace.save();
    }

    private getTags(tags: string) {
        return tags.split(',').map(i => {
            return i.trim();
        });
    }

    private onKeyDown(event: any) {
        this.editorService.multipleSelectionIsActive = event.key.toLowerCase() === 'shift';
    }

    private onKeyUp(event: any) {
        this.editorService.multipleSelectionIsActive = false;
    }
}
