import {Album} from '../../google-photo-api/objects/album';
import {WorkingMedia} from './working-media';
import {Row} from '../../pb-database/objects/row';

export class WorkingAlbum {

    public selectTags: Array<string> = [];
    public selectImportances: Array<number> = [];
    public viewMediaList: Array<WorkingMedia> = [];
    private workingMediaList: Array<WorkingMedia> = [];
    private allTagsList: Array<string> = [];
    private allImportancesList: Array<number> = [];

    private initialized = false;

    static unique(arr1: Array<any>) {
        return arr1.filter((el, index, arr) => {
            return index === arr.indexOf(el);
        });
    }

    constructor(
        private pAlbum: Album
    ) {
    }

    public isInitialied() {
        return this.initialized;
    }

    get album(): Album {
        return this.pAlbum;
    }

    initFromSheetValues(result: any) {
        if (result.values) {
            // initialiser depuis les données de sheets.
            this.initAlbumFromSheetValues(result.values);
        } else {
            // initialiser depuis les photos uniquement.
            let weight = 0;
            this.album.mediasList.map(media => {
                this.workingMediaList.push(
                    new WorkingMedia(media, weight++, 0, media.data.description, [], '')
                );
            });
        }

        this.initData();
        this.initialized = true;

        this.updateViewMediaList();
    }

    get workingMedia(): Array<WorkingMedia> {
        return this.workingMediaList;
    }

    private initAlbumFromSheetValues(values: Array<any>) {
        values.map(value => {
            const row = Row.getRowFromValue(value);

            this.workingMediaList.push(new WorkingMedia(
                this.album.getMediaById(row.id),
                row.weight,
                row.importance,
                row.description,
                row.tags.split(WorkingMedia.TAG_SEPARATOR).filter(i => {
                    return i.length > 0;
                }),
                row.overlayData
            ));
        });

        // Ajout des nouveaux médias.
        let weight = this.workingMediaList.length;
        this.album.mediasList.filter(media => {
            const result = this.getWorkingMediaById(media.id);
            return (typeof (result) === 'undefined') || (!result);
        }).map( media => {
            this.workingMediaList.push(
                new WorkingMedia(media, weight++, 0, media.data.description, [], '')
            );
        });
    }

    public updateViewMediaList() {
        this.viewMediaList = this.workingMediaList;

        if (this.selectTags.length > 0) {
            this.viewMediaList = this.viewMediaList.filter(media => {
                return this.selectTags.filter(value => media.tags.includes(value)).length > 0;
            });
        }

        if (this.selectImportances.length > 0) {
            this.viewMediaList = this.viewMediaList.filter(media => {
                return this.selectImportances.includes(media.importance);
            });
        }

        // Sort
        this.viewMediaList.sort((a, b) => {
            if (+a.weight < +b.weight) {
                return -1;
            }
            return 1;
        });
    }

    private initTagsList() {
        this.allTagsList = [];
        this.workingMedia.map(media => {
            this.allTagsList = this.allTagsList.concat(media.tags);
        });
        this.allTagsList = this.cleanFilterElements(this.allTagsList);
    }

    get allTags() {
        return this.allTagsList;
    }

    public toggleTag(tag) {
        if (this.tagIsSelected(tag)) {
            this.selectTags = this.selectTags.filter(i => {
                return i !== tag;
            });
        } else {
            this.selectTags.push(tag);
        }

        this.updateViewMediaList();
    }

    public tagIsSelected(tag) {
        return this.selectTags.indexOf(tag) > -1;
    }

    private initImportancesList() {
        this.allImportancesList = [];
        this.workingMedia.map(media => {
            this.allImportancesList.push(media.importance);
        });
        this.allImportancesList = this.cleanFilterElements(this.allImportancesList);
    }

    get allImportances() {
        return this.allImportancesList;
    }

    public toggleImportance(tag) {
        if (this.importanceIsSelected(tag)) {
            this.selectImportances = this.selectImportances.filter(i => {
                return i !== tag;
            });
        } else {
            this.selectImportances.push(tag);
        }

        this.updateViewMediaList();
    }

    public importanceIsSelected(tag) {
        return this.selectImportances.indexOf(tag) > -1;
    }

    public initData() {
        this.initImportancesList();
        this.initTagsList();
    }


    private cleanFilterElements(allItems: Array<any>) {
        allItems = WorkingAlbum.unique(allItems).filter(i => {
            return i && i.length > 0;
        });
        allItems.sort();
        return allItems;
    }

    public updateWeights() {
        for (let i = 0; i < this.workingMediaList.length; i++) {
            this.workingMediaList[i].weight = i;
        }
    }

    public getWorkingMediaById(id: string) {
        return this.workingMediaList.filter(i => {
            return i.media.id === id;
        })[0];
    }
}
