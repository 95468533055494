import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PbAuthComponent} from './pb-auth/pb-auth.component';
import {AuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {PbHubComponent} from './pb-hub/pb-hub.component';
import {PbHomeComponent} from './pb-home/pb-home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomApiModule} from './custom-api/custom-api.module';
import {provideConfig} from './pb-auth/services/pbauth.service';
import {GoogleAuthHttpInterceptor} from './pb-auth/interceptors/google-auth-http-Interceptor';
import {PbWorkspaceComponent} from './pb-workspace/pb-workspace.component';
import {PbDatabaseModule} from './pb-database/pb-database.module';
import { PbEditorComponent } from './pb-editor/pb-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule, MatDividerModule, MatIconModule} from '@angular/material';
import { PbFilterComponent } from './pb-filter/pb-filter.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PbViewComponent } from './pb-view/pb-view.component';
import {MatIcon} from "@angular/material/icon";

@NgModule({
    declarations: [
        AppComponent,
        PbAuthComponent,
        PbHubComponent,
        PbHomeComponent,
        PbWorkspaceComponent,
        PbEditorComponent,
        PbFilterComponent,
        PbViewComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SocialLoginModule,
        CustomApiModule,
        HttpClientModule,
        PbDatabaseModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatDividerModule,
        MatIconModule,
        DragDropModule,
        BrowserAnimationsModule,
    ],
    providers: [
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GoogleAuthHttpInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
