import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PBAuthService} from '../services/pbauth.service';

@Injectable()
export class GoogleAuthHttpInterceptor implements HttpInterceptor {
    constructor(private pbAuthService: PBAuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.pbAuthService.user.authToken}`
            }
        });

        return next.handle(request);
    }
}
