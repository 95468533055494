import {Media} from './media';

export class Album {

    private medias: Array<Media> = [];
    public allMediasHaveBeenLoaded = false;
    private _exists = false;

    constructor(
        private albumData: any
    ) {
    }

    getMediaById(id: string) {
        return this.medias.filter(i => {
            return i.id === id;
        })[0];
    }

    addMedia(media: Media) {
        this.medias.push(media);
    }

    get title(): string {
        return this.albumData.title;
    }

    get id(): string {
        return this.albumData.id;
    }

    get data(): string {
        return this.albumData;
    }

    get mediasList(): Array<Media> {
        return this.medias;
    }

    get exists(): boolean {
       return this._exists;
    }

    set exists(value: boolean) {
        this._exists = value;
    }
}
