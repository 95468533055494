import {Component, OnInit} from '@angular/core';
import {WorkspaceService} from '../pb-workspace/service/workspace.service';

@Component({
    selector: 'app-pb-filter',
    templateUrl: './pb-filter.component.html',
    styleUrls: ['./pb-filter.component.scss']
})
export class PbFilterComponent implements OnInit {

    constructor(
        private workspace: WorkspaceService
    ) {
    }

    ngOnInit() {
    }

}
