import {Injectable} from '@angular/core';
import {Api} from '../modele/Api';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiFactoryService {

    constructor(private http: HttpClient) {
    }

    create(): Api {
        return new Api(this.http);
    }
}
