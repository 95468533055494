import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PbHubComponent} from './pb-hub/pb-hub.component';
import {PbHomeComponent} from './pb-home/pb-home.component';
import {PbWorkspaceComponent} from './pb-workspace/pb-workspace.component';
import {PbViewComponent} from './pb-view/pb-view.component';


const routes: Routes = [
  {path: '', component: PbHomeComponent},
  {path: 'hub', component: PbHubComponent},
  {path: 'workspace/:title', component: PbWorkspaceComponent},
  {path: 'view/:title', component: PbViewComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
