import {Subject, Subscribable} from 'rxjs';

export class CustomObservable extends Subject<any> implements Subscribable<any> {

    private message: any;

    setMessage(newMessage: any): CustomObservable {
        this.message = newMessage;
        return this;
    }

}
