import {Subject, Subscription, Subscribable} from 'rxjs';

export class MockObservable extends Subject<any> implements Subscribable<any> {

    private message: any;

    public static getMock(message: any): Subject<any> {
        const mock = new MockObservable();
        mock.setMessage(message);
        return mock;
    }


    subscribe(observer: any): Subscription {
        const result = super.subscribe(observer);
        this.next(this.message);

        return result;
    }

    setMessage(newMessage: any): MockObservable {
        this.message = newMessage;

        return this;
    }

}
