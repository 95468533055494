export class Media {

    constructor(
        private mediaItem: any
    ) {
    }

    get id() {
        return this.mediaItem.id;
    }

    get title() {
        return this.mediaItem.filename;
    }

    get data() {
        return this.mediaItem;
    }

    get url() {
        return this.mediaItem.baseUrl + '=w2048-h1024';
    }

    get view() {
        if (this.mediaItem.mediaMetadata.photo) {
            return '<img src="' + this.url + '"/>';
        } else {
            return '<video controls autoplay loop>' +
                '<source src="' + this.mediaItem.baseUrl + '=dv" type="video/mp4">' +
                '</video>';
        }
    }

}
