import {Component, OnInit} from '@angular/core';
import {PBAuthService} from './services/pbauth.service';


@Component({
    selector: 'app-pb-auth',
    templateUrl: './pb-auth.component.html',
    styleUrls: ['./pb-auth.component.scss']
})
export class PbAuthComponent implements OnInit {

    constructor(
        private pbauth: PBAuthService
    ) {
    }

    ngOnInit() {
        this.pbauth.initListener();
    }

    signIn(): void {
        this.pbauth.signIn();
    }

    signOut(): void {
        this.pbauth.signOut();
    }

}
