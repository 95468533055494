import {Media} from '../../google-photo-api/objects/media';
import {Row} from '../../pb-database/objects/row';
import {WorkingAlbum} from './working-album';

export class WorkingMedia {

    static TAG_SEPARATOR = '|';

    public editionState = false;

    constructor(
        public media: Media,
        public weight: number,
        public importance: number,
        public description: string,
        public tags: Array<string>,
        public overlayData: string,
    ) {
        this.tags = WorkingAlbum.unique(this.tags);
    }

    toRow() {
        return new Row(
            this.media.id,
            this.media.title,
            this.weight,
            this.importance,
            this.tags.join(WorkingMedia.TAG_SEPARATOR),
            this.description,
            this.overlayData,
        );
    }

    addTags(tags: string[]) {
        this.tags = this.tags.concat(tags);
    }

    deleteTags(tags: string[]) {
        this.tags = this.tags.filter(i => {
            return tags.indexOf(i) < 0;
        });
    }
}
