import {Injectable} from '@angular/core';
import {WorkingAlbum} from '../objects/working-album';
import {PbDatabaseService} from '../../pb-database/service/pb-database.service';
import {AlbumsFactoryService} from '../../google-photo-api/factories/albums-factory.service';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {

    private pWorkingAlbum: WorkingAlbum;

    constructor(
        private albumRepository: AlbumsFactoryService,
        public pbDatabse: PbDatabaseService,
    ) {
    }

    get workingAlbum() {
        return this.pWorkingAlbum;
    }

    set workingAlbum(value) {
        this.pWorkingAlbum = value;
    }

    /**
     * Initialise la sheet de l'album courant si beson.
     */
    initSheet() {
        this.pbDatabse.initWorkingAlbumSheet(this.workingAlbum).subscribe(result => this.prepare());
    }

    /**
     * Initialise les medias.
     */
    initMedias() {
        // Récupératoin des médias depuis google photo
        this.albumRepository.getMediasOfAlbum(this.workingAlbum.album).subscribe(
            result => {
                // When everything is loaded.
                if (typeof (result.nextPageToken) === 'undefined') {
                    this.workingAlbum.album.allMediasHaveBeenLoaded = true;
                    this.prepare();
                }
            }
        );
    }

    /**
     * Prépare les données.
     */
    private prepare() {
        if (this.pbDatabse.sheetIsReady() && this.workingAlbum.album.allMediasHaveBeenLoaded) {
            this.pbDatabse.getSheetData().subscribe(result => this.workingAlbum.initFromSheetValues(result));
        }
    }

    /**
     * Save to the sheet.
     */
    public save() {
        // Formatage des données.
        const data = [];
        this.workingAlbum.workingMedia.map(workingMedia => {
            data.push(workingMedia.toRow().toArray());
        });

        this.pbDatabse.save(data);
    }
}
