import {WorkingMedia} from '../../pb-workspace/objects/working-media';

export class Row {
    constructor(
        private pId: string,
        private pName: string,
        private pWeight: number,
        private pImportance: number,
        private pTags: string = '',
        private pDescription: string = '',
        private pOverlayData: string = '',
    ) {
    }

    static getRowFromValue(value: Array<any>) {
        value.reverse();
        return new Row(
            value.pop(), // id
            value.pop(), // name
            value.pop(), // weight
            value.pop(), // importance
            value.pop(), // tags
            value.pop(), // description
            value.pop(), // overlayData
        );

    }

    toArray() {
        return [
            this.pId,
            this.pName,
            this.pWeight,
            this.pImportance,
            this.pTags,
            this.pDescription,
            this.pOverlayData,
        ];
    }


    get id(): string {
        return this.pId;
    }

    get name(): string {
        return this.pName;
    }

    get weight(): number {
        return this.pWeight;
    }

    get importance(): number {
        return this.pImportance;
    }

    get tags(): string {
        return this.pTags;
    }

    get description(): string {
        return this.pDescription;
    }

    get overlayData(): string {
        return this.pOverlayData;
    }
}
