import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkspaceService} from './service/workspace.service';
import {EditorService} from '../pb-editor/services/editor.service';
import {WorkingMedia} from './objects/working-media';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-pb-workspace',
    templateUrl: './pb-workspace.component.html',
    styleUrls: ['./pb-workspace.component.scss']
})
export class PbWorkspaceComponent implements OnInit {

    public current: WorkingMedia;
    private listener: (key) => void;

    constructor(
        private activatedRoute: ActivatedRoute,
        private workspace: WorkspaceService,
        private editionService: EditorService,
        private router: Router,
    ) {
        this.listener = (key) => this.onKeyUp(key);
    }

    ngOnInit() {
        // Check if albums is set.
        if (this.workspace.workingAlbum && !this.workspace.workingAlbum.isInitialied()) {
            this.workspace.initSheet();
            this.workspace.initMedias();
        }
    }

    open(workingMedia: WorkingMedia) {
        document.removeEventListener('keyup', this.listener);
        document.addEventListener('keyup', this.listener);
        this.current = workingMedia;
    }

    drop($event: CdkDragDrop<any, any>) {
        moveItemInArray(this.workspace.workingAlbum.workingMedia, $event.previousIndex, $event.currentIndex);
        this.workspace.workingAlbum.updateWeights();
        this.workspace.save();
    }

    view() {
        this.router.navigate(['/view/' + this.workspace.workingAlbum.album.title]);
    }

    private onKeyUp(key: any) {
        if (key.key.toLowerCase() === 'escape') {
            this.current = null;
            document.removeEventListener('keyup', this.listener);
        }
    }
}
