import {HttpClient} from '@angular/common/http';
import {Api} from './Api';
import {Observable} from 'rxjs';
import {HttpSubscription} from './HttpSubscription';

/**
 * Empèche les requètes asynchrone d'être jouée à la volée avant la réponse de la précédente.
 */
export class ApiHttpClient {

    private requestTimeout = 5000;

    constructor(private sync: boolean, private parentApi: Api, private httpClient: HttpClient) {
    }

    /**
     * Réécriture de la fonctin get pour gérer la synchronisation des request
     */
    public get(url, options: any = {}): HttpSubscription<ArrayBuffer> {
        const observable = this.httpClient.get(url, options);
        return this.initObservable(observable);
    }

    /**
     * Réécriture de la fonctin post pour gérer la synchronisation des request
     */
    public post(url: string, body: any, options: any = {}): HttpSubscription<ArrayBuffer> {
        const observable = this.httpClient.post(url, body, options);
        return this.initObservable(observable);
    }

    /**
     * Réécriture de la fonctin put pour gérer la synchronisation des requests
     */
    public put(url: string, body: any, options: any = {}): HttpSubscription<ArrayBuffer> {
        const observable = this.httpClient.put(url, body, options);
        return this.initObservable(observable);
    }

    /**
     * Réécriture de la fonctin delete pour gérer la synchronisation des request
     */
    public delete(url: string, options: any = {}): HttpSubscription<ArrayBuffer> {
        const observable = this.httpClient.delete(url, options);
        return this.initObservable(observable);
    }


    /**
     * Retourne l'objet httpClient.
     */
    get http(): HttpClient {
        return this.httpClient;
    }

    /**
     * Initialise la promesse avec synchronisation.
     */
    private initObservable(observable: Observable<ArrayBuffer>): HttpSubscription<ArrayBuffer> {
        return new HttpSubscription(observable, this);
    }


    /**
     * Retourne le timeout
     */
    get timeout(): number {
        return this.requestTimeout;
    }

    /**
     * Permet de modifier le timeout.
     */
    set timeout(value: number) {
        this.requestTimeout = value;
    }

    get api(): Api {
        return this.parentApi;
    }

    get isSync(): boolean {
        return this.sync;
    }

}
