import {Observable} from 'rxjs';
import {Subscribable} from 'rxjs/src/internal/types';
import {finalize, tap} from 'rxjs/operators';
import {ApiHttpClient} from './ApiHttpClient';

export class HttpSubscription<T> implements Subscribable<T> {
    protected subscription: any;
    protected timeout;
    protected timeoutDelay = 0;
    private timeoutCallback: any;

    constructor(private observable: Observable<ArrayBuffer>, private apiHttp: ApiHttpClient) {
    }

    public subscribe(observerOrNext: any,
                     error?: (error: any) => void,
                     complete?: () => void): HttpSubscription<T> {

        // Initialisation de la synchronisation.
        this.initSync();
        // Initialisation du timeout
        this.initTimeout();

        // On retourne la subscription.
        this.subscription = this.observable.subscribe(observerOrNext, error, complete);
        return this;
    }

    /**
     * Supprime la souscription.
     */
    public unsubscribe(): HttpSubscription<T> {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        return this;
    }

    /**
     * Ajoite un pipe.
     */
    public pipe(operations: any): HttpSubscription<T> {
        this.observable = this.observable.pipe(operations);
        return this;
    }

    /**
     * Initialise la gestion du timeout.
     */
    private initTimeout() {
        if (this.timeoutDelay > 0) {
            // On supprime la promesse si elle est trop longue.
            this.timeout = window.setTimeout(() => {
                this.subscription.unsubscribe();
                this.apiHttp.api.enable();
                if (this.timeoutCallback) {
                    this.timeoutCallback.call(this);
                }
            }, this.timeoutDelay);

            // On arrete le timeout une fois l'observable resolve.
            this.pipe(finalize(() => {
                window.clearTimeout(this.timeout);
            }));
        }

    }

    private initSync() {
        if (this.apiHttp.isSync) {
            this.apiHttp.api.disable();
            this
                .pipe(tap((data) => {
                    this.apiHttp.api.enable();
                }))
                .pipe(finalize(() => {
                    this.apiHttp.api.enable();
                }));
        }
    }

    public setTimeout(timeoutDelay: number, timeoutCallback: CallableFunction): HttpSubscription<T> {
        this.timeoutDelay = timeoutDelay;
        this.timeoutCallback = timeoutCallback;
        return this;
    }
}
