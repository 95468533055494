import {Injectable} from '@angular/core';
import {WorkingAlbum} from '../../pb-workspace/objects/working-album';
import {GoogleSheetsApiService} from '../../google-sheets-api/service/google-sheets-api.service';
import {CustomObservable} from '../../custom-api/modele/custom-observable';


interface SheetDataInterface {
    index: string;
    sheetId: string;
    title: string;
}

@Injectable({
    providedIn: 'root'
})
export class PbDatabaseService {

    private static API_KEY = 'AIzaSyDgrC5i8LyvbO9NP9CFpagG6gB351Tthk4';
    private static SHEET_NAME = 'photo_binder_data';
    private spreadSheet: string;
    private sheet: SheetDataInterface;
    private workingAlbum: WorkingAlbum;
    private spreadSheetObserver: CustomObservable;
    private sheetObservable: CustomObservable;

    constructor(
        public sheetsApi: GoogleSheetsApiService,
    ) {
        this.sheetsApi.setApiKey(PbDatabaseService.API_KEY);
    }

    /**
     * Initialise la spreadsheet.
     */
    public initSpreadSheet() {
        this.spreadSheetObserver = new CustomObservable();

        // Vérifier l'existance de la feuille de l'application.
        this.sheetsApi.getSpreadSheetByName(PbDatabaseService.SHEET_NAME).subscribe(
            result => {
                if (result.files.length === 0) {
                    console.log('Must create speadsheet');
                    this.createSpreadSheet();
                } else {
                    console.log('Use spreadsheet');
                    this.doInitSpreadSheet(result.files[0].id);
                }
            }
        );

        return this.spreadSheetObserver;
    }

    /**
     * Update ou crée la sheet de l'album en cours.
     */
    public initWorkingAlbumSheet(workingAlbum: WorkingAlbum) {
        this.sheet = null;
        this.sheetObservable = new CustomObservable();
        this.workingAlbum = workingAlbum;

        // Préparation de la tab.
        if (this.spreadSheet) {
            this.initSheet();
        } else {
            if (this.spreadSheetObserver) {
                // Si l'initialisation de la spreadhseet est en attente.
                this.spreadSheetObserver.subscribe(result => this.initSheet());
            } else {
                // Si l'initialisation de la spreadsheet n'a pas été lancée.
                this.initSpreadSheet().subscribe(result => this.initSheet());
            }
        }

        return this.sheetObservable;
    }

    /**
     * Crée la spreadsheet et balance un next au spreadSheetObservable quand prêt.
     */
    private createSpreadSheet() {
        this.sheetsApi.createSpreadSheet(PbDatabaseService.SHEET_NAME).subscribe(
            result => {
                this.doInitSpreadSheet(result.spreadsheetId);
            }
        );
    }

    /**
     * Initialise la row.
     */
    private initSheet() {
        this.sheetsApi.getSchema().subscribe(
            result => {
                const sheetData = result.sheets.filter(i => {
                    return i.properties.title === this.workingAlbum.album.id;
                })[0];

                if (sheetData) {
                    this.doInitSheet(sheetData.properties);
                } else {
                    this.createSheet();
                }
            },
            err => {
                console.log(err);
            }
        );

        return this.sheetObservable;
    }

    /**
     * Initialise les données de la main spreadsheet après création ou récupération.
     */
    private doInitSpreadSheet(id: any) {
        this.spreadSheet = id;
        this.sheetsApi.setMainSpreadSheet(this.spreadSheet);
        this.spreadSheetObserver.next(this.spreadSheet);
    }

    /**
     * Initialise les données de la sheet.
     */
    private doInitSheet(sheetData: any) {
        this.sheet = sheetData;
        this.sheetObservable.next(this.sheet);
    }

    /**
     * Crée la sheet liée àç l'album.
     */
    private createSheet() {
        this.sheetsApi.createSheet(this.workingAlbum.album.id).subscribe(
            result => {
                this.doInitSheet(result.replies[0].addSheet.properties);
            }
        );
    }

    public sheetIsReady() {
        return this.sheet !== null && typeof (this.sheet) !== 'undefined';
    }

    getSheetData() {
        return this.sheetsApi.values(this.sheet.title);
    }

    save(values: any[]) {
        this.sheetsApi.clear(this.sheet.title).subscribe(clearResult => {
            this.sheetsApi.append(this.sheet.title, values).subscribe(
                result => {
                    console.log('that is ok');
                }
            );
        });
    }
}
