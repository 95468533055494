import {Injectable} from '@angular/core';
import {AuthService, AuthServiceConfig, GoogleLoginProvider, LoginOpt, SocialUser} from 'angularx-social-login';
import {Router} from '@angular/router';


/**
 * Connexion configuration.
 */
const CLIENT_ID = '929180157914-df9hivmtlevvo4mpbm95s59ecbs7ski9.apps.googleusercontent.com';
const scopes = [
    'https://www.googleapis.com/auth/photoslibrary',
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/spreadsheets',
];

const googleLoginOptions: LoginOpt = {
    scope: scopes.join(' ')
};
const config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(CLIENT_ID, googleLoginOptions)
    }
]);

export function provideConfig() {
    return config;
}


@Injectable({
    providedIn: 'root'
})
export class PBAuthService {

    public user: SocialUser;
    public loggedIn: boolean;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    public initListener() {
        this.authService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);


            // Redirection.
            if (this.loggedIn) {
                this.router.navigate(['/hub']);
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    public signIn() {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    public signOut() {
        this.authService.signOut();
    }
}
