import {Component, OnInit} from '@angular/core';
import {AlbumsFactoryService} from '../google-photo-api/factories/albums-factory.service';
import {AuthService} from 'angularx-social-login';
import {WorkspaceService} from '../pb-workspace/service/workspace.service';
import {Router} from '@angular/router';
import {WorkingAlbum} from '../pb-workspace/objects/working-album';
import {PbDatabaseService} from '../pb-database/service/pb-database.service';

@Component({
    selector: 'app-pb-hub',
    templateUrl: './pb-hub.component.html',
    styleUrls: ['./pb-hub.component.scss']
})
export class PbHubComponent implements OnInit {

    constructor(
        private albumsRepository: AlbumsFactoryService,
        private auth: AuthService,
        private workspace: WorkspaceService,
        private router: Router,
        private pbDatabse: PbDatabaseService,
    ) {
    }

    ngOnInit() {
        this.auth.authState.subscribe(
            user => {
                if (user) {
                    // Initialisation des données utilisaeur.
                    this.initUserData();
                }
            }
        );
    }

    /**
     * Initialise la liste des albums.
     */
    getAlbums() {
        this.albumsRepository.getAlbums().subscribe( () => this.onAlbumsReady());
    }

    /**
     * Créer un album de travail.
     * @param string id
     *   The id of the album.
     */
    createBinder(id: string) {
        this.workspace.workingAlbum = new WorkingAlbum(this.albumsRepository.findAlbumById(id));
        this.router.navigate(['/workspace/' + this.workspace.workingAlbum.album.title]);

    }

    private initUserData() {
        // initialisation des albums.
        this.getAlbums();

        // Initialisation de la spreadsheet.
        this.pbDatabse.initSpreadSheet()
    }

  /**
   * On va chercher les titre des sheets pour chaque album si existe.
   */
  private onAlbumsReady() {
    this.pbDatabse.sheetsApi.getSchema().subscribe(result => {
      // Pour chaque sheet, on ajoute son état dans l'album correspondant.
      result.sheets.forEach(item => {
        const title = item.properties.title;

        // On tag les albums déjà existants dans la spreadsheet.
        this.albumsRepository.albums
            .filter(album => {
                return album.id === title
            })
            .forEach( album => {
                album.exists = true;
            });
      })
    })
  }
}
