import {Injectable} from '@angular/core';
import {WorkingMedia} from '../../pb-workspace/objects/working-media';
import {CustomObservable} from '../../custom-api/modele/custom-observable';
import {WorkspaceService} from '../../pb-workspace/service/workspace.service';

@Injectable({
    providedIn: 'root'
})
export class EditorService {
    public editionList: Array<WorkingMedia> = [];
    public editionState = false;
    private changesObservable: CustomObservable;
    multipleSelectionIsActive = false;

    constructor(
        private workspace: WorkspaceService,
    ) {
        this.changesObservable = new CustomObservable();
    }

    private add(workingMedia: WorkingMedia) {
        workingMedia.editionState = true;
        if (!this.editionList.includes(workingMedia)) {
            this.editionList.push(workingMedia);
        }

        this.editionList.sort((a, b) => {
            if (a.weight < b.weight) {
                return -1;
            }
            return 1;
        });
    }

    private delete(workingMedia: WorkingMedia) {
        workingMedia.editionState = false;
        this.editionList = this.editionList.filter(
            i => {
                return i.media.id !== workingMedia.media.id;
            }
        );
    }

    toggleEditionState(workingMedia: WorkingMedia) {
        if (this.multipleSelectionIsActive && this.editionList.length > 0) {
            this.workspace.workingAlbum.viewMediaList.filter(i => {
                return (
                    i.weight >= this.editionList[0].weight
                    && i.weight <= workingMedia.weight
                );
            }).map(wMedia => {
                this.add(wMedia);
            });
        } else {
            if (workingMedia.editionState) {
                this.delete(workingMedia);
            } else {
                this.add(workingMedia);
            }
        }


        this.changesObservable.next(this.editionList);
    }

    public toggleWorkspaceState() {
        this.editionState = !this.editionState;

        if (!this.editionState) {
            this.unselectAll();
        }

        this.changesObservable.next(this.editionList);
    }

    get changesObserver(): CustomObservable {
        return this.changesObservable;
    }

    public unselectAll() {
        this.editionList.map(media => {
            this.delete(media);
        });
        this.editionList = [];

        this.changesObservable.next(this.editionList);
    }

    public selectAll() {
        this.workspace.workingAlbum.viewMediaList.map(workingMedia => {
            this.add(workingMedia);
        });
        this.changesObservable.next(this.editionList);
    }
}
