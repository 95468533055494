import {Injectable} from '@angular/core';
import {ApiFactoryService} from '../../custom-api/service/api-factory.service';
import {Api} from '../../custom-api/modele/Api';
import {tap} from 'rxjs/operators';
import {MockObservable} from '../../custom-api/modele/MockObservable';
import {Subscribable} from 'rxjs';

interface PSchema {
    sheets: [];
}

@Injectable({
    providedIn: 'root'
})
export class GoogleSheetsApiService {

    public DRIVE_BASE_URL = 'https://www.googleapis.com/drive/v3/';
    public SHEETS_BASE_URL = 'https://sheets.googleapis.com/v4/spreadsheets/';
    public sheetsApi: Api;
    private cache = {};
    private url: string;
    private apiKey: string;

    private pSchema: PSchema;
    private mainSpreadSheet: string;

    constructor(
        private apiFactory: ApiFactoryService
    ) {
        this.sheetsApi = this.apiFactory.create();
    }

    /**
     * Retourne le schema si accessible.
     */
    public getSchema(force: boolean = false): any {
        if (!this.pSchema || this.pSchema.sheets.length === 0 || force) {
            const endpoint = this.endpoint();

            return this.sheetsApi.sync.get(endpoint, {})
                .pipe(tap((data: PSchema) => {
                    this.pSchema = data;
                }));
        }
        return MockObservable.getMock(this.pSchema);
    }

    setApiKey(apiKey: string) {
        this.apiKey = apiKey;
    }

    setMainSpreadSheet(id: string) {
        this.mainSpreadSheet = id;
        this.url = this.SHEETS_BASE_URL + this.mainSpreadSheet;
    }

    /**
     * Accesseur de l'api.
     */
    get api(): Api {
        return this.sheetsApi;
    }

    /**
     * Retourne le schema
     */
    get schema(): any {
        return this.pSchema;
    }

    /**
     * Ajoute les données au cache.
     * @param sheet srting
     * @param data any
     */
    private addToCache(sheet: string, data: any): void {
        this.cache[sheet] = data;
    }

    /**
     * Retourne l'endpoint.
     * @param other string
     * @param params any
     */
    private endpoint(other: string = '', params: any = {}): string {
        params.key = this.apiKey;
        return this.url + '/' + other + '?' + this.paramsToUrl(params);
    }

    public paramsToUrl(params: any): string {
        let url = '';
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                url += key + '=' + params[key] + '&';
            }
        }
        return url.substring(0, url.length - 1);
    }

    /**
     * Execute un batch update.
     *
     * @param sheet string
     * @param request any
     */
    public batchUpdate(data: any) {
        const endpoint = this.endpoint(':batchUpdate');
        return this.sheetsApi.sync.post(endpoint, data);
    }

    /**
     * Retourne les donénes de la table.
     * @param sheet strng
     * @param force boolean
     */
    public values(sheet: string, force = false): Subscribable<any> {
        if (!this.cache[sheet] || force) {

            const endpoint = this.endpoint('values/' + sheet);

            return this.sheetsApi.async.get(endpoint)
                .pipe(tap(data => this.addToCache(sheet, data)));
        }

        return MockObservable.getMock(this.cache[sheet]);
    }

    /**
     * Ajoute les données à la sheet.
     */
    public append(sheet: string, data: Array<Array<string>>): Subscribable<any> {
        const endpoint = this.endpoint('values/' + sheet + ':append', {valueInputOption: 'USER_ENTERED'});
        return this.sheetsApi.sync.post(endpoint,
            {
                values: data,
            }
        );
    }

    public clear(sheet: string) {
        const endpoint = this.endpoint('values/' + sheet + ':clear');
        return this.sheetsApi.sync.post(endpoint, {});
    }

    public getSpreadSheetByName(name: string, params: any = {}) {
        params = {
            key: this.apiKey,
            q: 'name="' + name + '"',
        };

        return this.sheetsApi.sync.get(this.DRIVE_BASE_URL + 'files?' + this.paramsToUrl(params));
    }

    public createSpreadSheet(name: string, params: any = {}, data: any = {}) {
        params = {
            key: this.apiKey
        };

        data.properties = data.properties || {};
        data.properties.title = name;

        return this.sheetsApi.sync.post(this.SHEETS_BASE_URL + '?' + this.paramsToUrl(params), data);
    }


    /**
     * Crée une sheet.
     */
    public createSheet(sheetName: string) {
        return this.batchUpdate({
            requests: [
                {
                    addSheet: {
                        properties: {
                            title: sheetName
                        }
                    }
                }
            ]
        });
    }


}
