import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkingMedia} from '../pb-workspace/objects/working-media';
import {WorkspaceService} from '../pb-workspace/service/workspace.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-pb-view',
    templateUrl: './pb-view.component.html',
    styleUrls: ['./pb-view.component.scss']
})
export class PbViewComponent implements OnInit, OnDestroy {

    private current: WorkingMedia;
    private index = 0;
    private inputListener: (event) => void;

    constructor(
        private workspace: WorkspaceService,
        private router: Router,
    ) {
        this.inputListener = event => this.onKeyPress(event.key);
    }

    ngOnInit() {
        this.index = 0;
        this.setCurrent(this.index);

        document.removeEventListener('keyup', this.inputListener, true);
        document.addEventListener('keyup', this.inputListener, true);

        document.documentElement.requestFullscreen();
    }

    ngOnDestroy() {
        document.removeEventListener('keyup', this.inputListener, true);
    }

    private setCurrent(index: number) {
        if (this.index < 0 || this.index > this.workspace.workingAlbum.viewMediaList.length) {
            this.index = 0;
        }
        this.current = this.workspace.workingAlbum.viewMediaList[this.index];
    }

    private onKeyPress(key: string) {
        switch (key.toLowerCase()) {
            case ' ':
            case 'arrowright':
                this.setCurrent(this.index++);
                break;
            case 'arrowleft':
                this.setCurrent(this.index--);
                break;
            case 'escape':
                document.removeEventListener('keyup', this.inputListener, true);
                this.router.navigate(['/workspace/' + this.workspace.workingAlbum.album.title]);
                break;
        }
    }
}
