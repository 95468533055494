import {Injectable} from '@angular/core';
import {ApiFactoryService} from '../../custom-api/service/api-factory.service';
import {Api} from '../../custom-api/modele/Api';
import {Album} from '../objects/album';
import {Media} from '../objects/media';
import {Subject, Subscribable} from 'rxjs';
import {CustomObservable} from '../../custom-api/modele/custom-observable';
import {MockObservable} from '../../custom-api/modele/MockObservable';

@Injectable({
    providedIn: 'root'
})
export class AlbumsFactoryService {

    private api: Api;


    private url = 'https://photoslibrary.googleapis.com/v1/';
    private pAlbums: Array<Album> = [];

    constructor(
        private apiFactory: ApiFactoryService
    ) {
        this.api = this.apiFactory.create();
    }

    /**
     * Return a list of aklbums.
     *
     * There is a cache system so the google api is only called once.
     */
    public getAlbums(): Subscribable<any> {
        let subscribable: Subject<any>;

        if (this.pAlbums.length === 0) {
            subscribable = new CustomObservable();
            this.api.sync.get(this.url + 'albums').subscribe(
                result => {
                    result.albums.map(i => {
                        this.pAlbums.push(new Album(i));
                    });
                    subscribable.next(this.pAlbums);
                },
            );
        } else {
            subscribable = MockObservable.getMock(this.pAlbums);
        }

        return subscribable;
    }

    /**
     * Return the wanted album.
     *
     */
    public findAlbumById(id: string) {
        return this.pAlbums.filter(i => {
            return i.id === id;
        })[0];
    }

    /**
     * Get the list of medias of an album.
     */
    public getMediasOfAlbum(album: Album, nextPageToken: string = null) {
        const observer = new CustomObservable();

        this.loopOnMediaList(observer, album, nextPageToken);
        return observer;
    }

    /**
     * Loop on pages.
     */
    private loopOnMediaList(observer: CustomObservable, album: Album, nextPageToken: string) {
        this.api.sync.post(this.url + 'mediaItems:search',
            {
                pageSize: '100',
                albumId: album.id,
                pageToken: nextPageToken
            })
            .subscribe(result => {

                // Ajout des médias à l'albums
                result.mediaItems.map(mediaItem => {
                    album.addMedia(new Media(mediaItem));
                });

                // Observer next
                observer.next(result);

                if (result.nextPageToken) {
                    this.loopOnMediaList(observer, album, result.nextPageToken);
                }
            });
    }

    /**
     * Albums
     */
    get albums(): Array<Album> {
        return this.pAlbums;
    }

    get isEnabled(): boolean {
        return this.api.isEnabled;
    }
}
