import {HttpClient} from '@angular/common/http';
import {ApiHttpClient} from './ApiHttpClient';

export class Api {

    private asyncHttp: ApiHttpClient;
    private syncHttp: ApiHttpClient;
    private apiEnabled = true;
    private apiTimeout = 5000;

    constructor(http: HttpClient) {
        this.asyncHttp = new ApiHttpClient(false, this, http);
        this.syncHttp = new ApiHttpClient(true, this, http);
        this.initTimeout();
    }

    get async(): ApiHttpClient {
        return this.asyncHttp;
    }

    get isEnabled(): boolean {
        return this.apiEnabled;
    }

    get timeout(): number {
        return this.apiTimeout;
    }

    set timeout(value: number) {
        this.apiTimeout = value;
        this.initTimeout();
    }

    /**
     * Désactive les appels à l'api en mode sync
     */
    public disable(): Api {
        this.apiEnabled = false;
        return this;
    }

    /**
     * Active l'api en mode sync.
     */
    public enable(): Api {
        this.apiEnabled = true;
        return this;
    }

    /**
     * Retourne le httpclient en mode sync.
     */
    get sync(): ApiHttpClient {
        if (this.isEnabled) {
            return this.syncHttp;
        } else {
            return null;
        }
    }

    /**
     * Initialise le timeout.
     */
    private initTimeout() {
        this.asyncHttp.timeout = this.syncHttp.timeout = this.timeout;
    }
}
